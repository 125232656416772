<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-form-group class="required-control">
              <label>Tên nhóm nhân sự: </label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập tên nhóm nhân sự"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label>Mã nhóm:</label>
              <b-form-input
                size="sm"
                v-model="mainModel.code"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập mã nhóm nhân sự..."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label style="font-weight: 500px"
              >Danh sách nhân sự phụ trách:</label
            >
            <multiselect
              v-model="mainModel.employeeGroupDetails"
              placeholder="Tìm kiếm theo tên nhân sự"
              label="employeeName"
              track-by="employeeName"
              :options="listEmployee"
              :multiple="true"
              :taggable="true"
              :selectLabel="'Chọn nhân sự'"
              :deselectLabel="'Xoá nhân sự'"
              :preserve-search="true"
              :close-on-select="false"
              :preselect-first="true"
              :clear-on-select="false"
            ></multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label style="font-weight: 500px"
              >Danh sách cửa hàng phụ trách:</label
            >
            <multiselect
              v-model="mainModel.employeeGroupStores"
              placeholder="Tìm kiếm theo tên cửa hàng"
              label="storeName"
              track-by="storeName"
              :options="listStore"
              :multiple="true"
              :taggable="true"
              :selectLabel="'Chọn cửa hàng'"
              :deselectLabel="'Xoá cửa hàng'"
              :preserve-search="true"
              :close-on-select="false"
              :preselect-first="true"
              :clear-on-select="false"
            ></multiselect>
          </b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="action && !isBusy && checkPermission('EMPLOYEE_GROUP_INSERT')"
          @click="onSubmitDebounce()"
        >
          {{ actionName }}
        </b-button>
        <b-button
          v-if="isBusy"
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          disabled
        >
          <b-spinner small type="grow"></b-spinner>
          {{ actionName }}...
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
          >Trở về</b-button
        >
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { TIME_TRIGGER } from '@/utils/constants';
import { makeToastSuccess, handleError } from '@/utils/common';
import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect';
import { cmdUrl } from '@/utils/apiUrl';

export default {
  data() {
    return {
      listEmployee: [],
      listStore: [],
      actionName: 'Lưu',
      action: null,
      title: 'Thêm nhóm nhân sự',
      mainModel: {
        id: null,
        name: null,
        code: null,
        employeeGroupDetails: [],
        employeeGroupStores: [],
      },
      isBusy: false,
    };
  },
  components: {
    KTCodePreview,
    Multiselect,
  },
  async created() {
    const { id, action } = this.$route.query;
    this.action = action;
    const promises = [this.getListEmployee(), this.getListStore()];
    if (id) {
      this.mainModel.id = id;
      promises.push(this.getEmployeeGroupInfo());
      this.title = 'Cập nhật cấu hình nhóm nhân sự';
    }
    await Promise.all(promises);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getEmployeeGroupInfo() {
      if (this.isBusy) return;
      try {
        this.isBusy = true;
        const endPoint = cmdUrl.employeeGroup.byId.replace(
          '{id}',
          this.mainModel.id,
        );
        const { data } = (await ApiService.get(endPoint)) || {};
        this.mainModel = data?.data;
      } catch (error) {
        handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    async getListEmployee() {
      const {
        data: { data },
      } = await ApiService.query('employees/getAll');
      this.listEmployee = data.map((item) => {
        return {
          employeeId: item.id,
          employeeName: item.fullName,
        };
      });
    },
    async getListStore() {
      const {
        data: { data },
      } = await ApiService.query('stores/getStores');
      this.listStore = data.map((item) => {
        return {
          storeId: item.id,
          storeName: item.name,
        };
      });
    },
    createEmployeeGroup(body) {
      const endPoint = cmdUrl.employeeGroup.root;
      return ApiService.post(endPoint, body);
    },
    updateEmployeeGroup(body) {
      const endPoint = cmdUrl.employeeGroup.byId.replace(
        '{id}',
        this.mainModel.id,
      );
      return ApiService.put(endPoint, body);
    },
    dispatchEmployeeGroupRequest(action, body) {
      switch (action) {
        case 'create':
          return this.createEmployeeGroup(body);
        case 'update':
          return this.updateEmployeeGroup(body);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    onSubmitDebounce: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    async onSubmit() {
      if (this.isBusy) return;
      this.isBusy = true;
      try {
        const result = await this.dispatchEmployeeGroupRequest(
          this.action,
          this.mainModel,
        );
        makeToastSuccess(result.data.message);
        this.goBack();
      } catch (error) {
        handleError(error);
      } finally {
        this.isBusy = false;
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
</style>
